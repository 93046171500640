import React, { useState } from 'react';
import PageTitle from '../../common/headings/PageTitle';
import PdfViewer from '../../common/PdfViewer';
import LanguageSelector from './LanguageSelector';

export default function UserGuideView(props) {
  const { setDocumentTitle } = props;

  const enFile = '/pdfs/documentation_en.pdf';
  const jpFile = '/pdfs/documentation_jp.pdf';

  const [file, setFile] = useState(enFile)
  
  React.useEffect(() => setDocumentTitle('3S | User Guide'))

  const languageSelector = (
    <LanguageSelector
      selectedFile={file}
      enFile={enFile}
      jpFile={jpFile}
      onChange={(e) => setFile(e.target.value)}
    />
  );

  return (
    <React.Fragment>
      <PageTitle>User Guide</PageTitle>
      <PdfViewer file={file} />
    </React.Fragment>
  )
}
