export const en = 'en';
export const jp = 'jp';

export const techReferences = [
  { 
    navName: 'Design, Evaluation and Implementation of Algorithms for Predicting Software Defects and Failures During the Operational Phase', 
    urlName: 'predicting-software-defects-and-failures', 
    en: '10_ISSAT_RQD_2024_en.pdf',
    authors: 'Kazuhira Okumoto',
    publications: '29th ISSAT Reliability and Quality in Design, August 2024, Miami, FL, USA.' 
  },
  { 
    navName: 'Digital Engineering-driven Software Quality Assurance-as-a-Service', 
    urlName: 'engineering-driven-software-quality-assurance', 
    en: '9_ISSAT_2023_en.pdf',
    authors: 'Kazuhira Okumoto',
    publications: '28th ISSAT Reliability and Quality in Design, August 2023, San Francisco, CA USA' 
  },
  { 
    navName: 'Early Software Defect Prediction: Right-Shifting Software Effort Data into a Defect Curve', 
    urlName: 'right-shifting-software-effort-data', 
    en: '8_ISSRE_STAR_Paper_en.pdf',
    authors: 'Kazuhira Okumoto',
    publications: '2022 IEEE International Symposium on Software Reliability Engineering Workshops (ISSREW), Oct 2022, pp. 43-48, Charlotte, NC, USA.' 
  },
  { 
    navName: 'STAR: A Novel Data-Driven Tool for Software Quality Assurance', 
    urlName: 'novel-data-driven-tool', 
    en: '7_STAROverview_en.pdf',
    authors: 'Kazuhira Okumoto',
    publications: 'Private Communication, 2022'
  },
  { 
    navName: 'Software Reliability Assurance in Practice', 
    urlName: 'software-reliability-assurance-in-practice', 
    en: '6_Encyclopedia_EEE_2019_en.pdf',
    authors: 'Rashid Mijumbi, Kazu Okumoto, Abhaya Asthana',
    publications: 'Chapter in Wiley Encyclopedia of Electrical and Electronics Engineering, 2019'
  },
  { 
    navName: 'Towards Automated, End-to-End Software Defect Prediction', 
    urlName: 'end-to-end-software-defect-prediction', 
    en: '5_ISSAT_2019_en.pdf',
    authors: 'Rashid Mijumbi, Kazuhira Okumoto, Abhaya Asthana',
    publications: '25th ISSAT Reliability and Quality in Design, August 2019, Las Vegas, Nevada USA' 
  },
  { 
    navName: 'Software Quality Assurance', 
    urlName: 'software-quality-assurance', 
    en: '4_BRACE.BookChapter_IntechOpen_2018_en.pdf',
    authors: 'Kazu Okumoto, Rashid Mijumbi, Abhaya Asthana',
    publications: 'Chapter in Telecommunication Networks: Trends and Developments, Intech Open, Nov 2018. [Online]. Available: https://www.intechopen.com/download/pdf/62937'
  },
  { 
    navName: 'Recent Advances in Software Reliability Assurance', 
    urlName: 'advances-in-software-reliability-assurance', 
    en: '3_BRACE.ISSRE_2018_en.pdf',
    authors: 'Rashid Mijumbi, Kazu Okumoto, Abhaya Asthana, Jacques Meekel',
    publications: '2018 IEEE International Symposium on Software Reliability Engineering Workshops (ISSREW), Oct 2018, pp. 77-82, Memphis, TN, USA.'
  },
  { 
    navName: 'BRACE: Cloud-based Software Reliability Assurance', 
    urlName: 'cloud-based-software-reliability-assurance', 
    en: '2_BRACE.ISSRE_2017_en.pdf',
    authors: 'Kazuhira Okumoto, Abhaya Asthana, Rashid Mijumbi',
    publications: '2017 IEEE International Symposium on Software Reliability Engineering Workshops (ISSREW), Oct 2017, pp. 57-60, Toulouse, France.'
  },
  { 
    navName: 'Experience Report: Practical Software Availability Prediction in Telecommunication Industry', 
    urlName: 'prediction-in-telecommunication-industry', 
    en: '1_ISSRE_106_en.pdf',
    authors: 'Kazuhira Okumoto',
    publications: '2016 IEEE International Symposium on Software Reliability Engineering (ISSRE), Oct 2016, pp. 331-342, Ottawa, Canada.' 
  },
]